<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="createSearchWidget"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped is-hoverable">
        <tr>
          <td>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Subtitle_Name') }}</td>
          <td><input v-model="mWidget.Name" class="input" /></td>
        </tr>
        <tr>
          <td>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_ShowCountrySelect') }}</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowCountrySelect }">
                  <a @click="mWidget.ShowCountrySelect = true">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_Yes') }}</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowCountrySelect }">
                  <a @click="mWidget.ShowCountrySelect = false">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_No') }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_ShowSearchInput') }}</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowSearchInput }">
                  <a @click="mWidget.ShowSearchInput = true">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_Yes') }}</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowSearchInput }">
                  <a @click="mWidget.ShowSearchInput = false">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_No') }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_ShowMeetingTypeSelection') }}</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowMeetingtypeSelect }">
                  <a @click="mWidget.ShowMeetingtypeSelect = true">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_Yes') }}</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowMeetingtypeSelect }">
                  <a @click="mWidget.ShowMeetingtypeSelect = false">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_No') }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_DefaultMeetingType') }}</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{'is-active': mWidget.PreSelectedMeetingtype === 0,}">
                  <a @click="mWidget.PreSelectedMeetingtype = 0">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_None') }}</span>
                  </a>
                </li>
                <li
                  v-for="(meetingtype, index) in locationState.Meetingtypes"
                  :key="index"
                  :class="{
                    'is-active':
                      mWidget.PreSelectedMeetingtype ===
                      meetingtype.MeetingtypeId,
                  }"
                >
                  <a
                    @click="
                      mWidget.PreSelectedMeetingtype = meetingtype.MeetingtypeId
                    "
                  >
                    <span>{{$options.filters.getMeetingtypeName(meetingtype.MeetingtypeId)}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_ShowOptionsStep') }}</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowOptionsStep }">
                  <a @click="mWidget.ShowOptionsStep = true">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_Yes') }}</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowOptionsStep }">
                  <a @click="mWidget.ShowOptionsStep = false">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_No') }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_ShowBookingDetailsStep') }}</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowBookingDetailsStep }">
                  <a @click="mWidget.ShowBookingDetailsStep = true">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_Yes') }}</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowBookingDetailsStep }">
                  <a @click="mWidget.ShowBookingDetailsStep = false">
                    <span>{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Label_No') }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
              <div>
          <p class="is-italic has-text-centered">{{ $t('Components.Settings.Widgets.ModalAddSearchWidget.Text_Info') }}</p>
        </div> 
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import widgetProvider from '@/providers/widget'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mWidget: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {
    this.mWidget = {
      Name: '',
      Type: 1,
      ItemId: this.locationState.Id,
      ShowCountrySelect: false,
      ShowSearchInput: false,
      ShowMeetingtypeSelect: this.locationState.Meetingtypes.length > 1,
      PreSelectedMeetingtype: this.locationState.Meetingtypes[0].MeetingtypeId,
      ShowOptionsStep: true,
      ShowBookingDetailsStep: true,
    }
  },

  methods: {
    createSearchWidget() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        widgetProvider.methods
          .createLocationSearchWidgets(this.mWidget)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.$emit('widgetCreated')

              setTimeout(() => {
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    },
  },
}
</script>
